

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

import UpdateUserInfo from "@/views/UpdateUserInfo.vue";
@Component({ components: { UpdateUserInfo } })
export default class ManageProcessor extends mixins(CommonMixin) {
  public brokerStaff = [];
  public isChecked = true;
  public brokerStaffInfo: any = {};
  public duplicateEmail = false;
  public inProcess = false;
  public activateWemPro = true;
  public roles = null;
  public staffData = null;
  public validation = [];
  public staff = [];
  public errorMessage = "";
  public personalInfo: any = [];
  public processorLoader = false;
  public taskRole = [];
  public loader = true;

  /**
   * to update the processor activity
   */
  public async switchBalancing(i, processorId) {
    try {
      let sure = false;
      let msg = !this.brokerStaff[i].inLoadBalancing
        ? "Are you sure you want to stop sending new loan?"
        : "Are you sure you want to  send new loan?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.brokerStaff[i].inLoadBalancing = !this.brokerStaff[i]
            .inLoadBalancing;
          this.$snotify.error("No changes");
        });
      if (sure) {
        await this.updateProcessorAudiTrail({
          performedBy: this.$store.state.sessionObject.userId,
          activity: this.brokerStaff[i].inLoadBalancing,
          processorId: processorId
        });
        // let response = await Axios.post(
        //   BASE_API_URL + "wemloStaff/updateProcessorAudiTrail",
        //   {
        //     performedBy: this.$store.state.sessionObject.userId,
        //     activity: this.brokerStaff[i].inLoadBalancing,
        //     processorId: processorId
        //   },
        //   {
        //
        //   }
        // );
        // this.$snotify.success("Updated");
      }
    } catch (error) {
      console.log(error, "switchBalancing");
    }
  }

  public openAddBrokerStaffModal() {
    this.$modal.show("addBrokerStaff");
  }
  public cancel() {
    try {
      this.$modal.hide("addBrokerStaff");
      this.$modal.hide("editDetails");

      this.brokerStaffInfo = {};
    } catch (error) {
      console.log(error, "cancel");
    }
  }
  /**
   * To add processor
   */
  public async addBrokerProcessor() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      this.inProcess = true;
      this.brokerStaffInfo.addedByUserType = this.$userType;
      let response = await Axios.post(
        BASE_API_URL + "broker/addBrokerProcessor",
        {
          brokerStaffInfo: this.brokerStaffInfo,
          userId: this.$userId
        });
      if (response.data.emailExists) {
        this.duplicateEmail = true;
        this.inProcess = false;
      } else {
        this.inProcess = false;
        this.$snotify.success("Staff added successfully");
        this.cancel();
        this.getBrokerStaffWithRoles();
      }
    } catch (error) {
      this.inProcess = false;

      console.log(error);
    }
  }
  /**
   * Calling from @change "On click of checkbox"
   */
  public changeValue(role, j, brokerStaff, i) {
    try {
      if (role.id == 7 && role.checked) {
        this.taskRole.forEach(t => (t.checked = true));
        this.pushTask(i);
      } else if (role.id == 7 && !role.checked) {
        this.taskRole.forEach(t => (t.checked = false));
        this.pushTask(i);
      } else {
        this.staff.push({
          taskId: this.brokerStaff[i].roles[j].id,
          processorId: this.brokerStaff[i].userId,
          checked: this.brokerStaff[i].roles[j].checked
        });
      }
    } catch (error) {
      console.log(error, "changeValue");
    }
  }

  public pushTask(i) {
    this.taskRole.forEach(e => {
      let obj = {
        taskId: e.id,
        processorId: this.brokerStaff[i].userId,
        checked: e.checked
      };
      this.staff.push(obj);
    });
  }
  /**
   * Saving processorId against role
   */
  public async saveProcessorAgainstRole() {
    try {
      this.processorLoader = true;
      if (this.brokerStaff.length > 0) {
        this.errorMessage = "No processor have been assigned to\n";
        this.validate();
        this.validation.length > 0
          ? (this.processorLoader = false)
          : (this.processorLoader = true);
        if (this.validation.length == 0) {
          let response = await Axios.post(
            BASE_API_URL + "broker/saveProcessorAgainstRole",
            {
              brokerStaff: this.staff,
              userId: this.$userId
            });
          this.staff = [];
          this.$snotify.success("Changes added successfully");
          this.processorLoader = false;
        }
      }
      this.processorLoader = false;
    } catch (error) {
      console.log(error, "saveProcessorAgainstRole");
      this.processorLoader = false;
    }
  }
  /**
   * To check if any role doesnot have any processor
   */
  public async validate() {
    try {
      this.validation = this.roles.filter(r => {
        return this.brokerStaff.find(bStaff =>
          bStaff.roles.find(role => role.id == r.id && role.checked)
        )
          ? false
          : true;
      });
      this.validation.forEach(e => {
        this.errorMessage += e.name + "\n";
      });
      this.roles.map(e => {
        e["notSelected"] = this.validation.find(role => role.id == e.id)
          ? true
          : false;
        return e;
      });
      this.brokerStaff.forEach(ele => {
        ele.roles.map(e => {
          e["notSelected"] = this.validation.find(role => role.id == e.id)
            ? true
            : false;
          return e;
        });
      });
    } catch (error) {
      console.log(error, "validate");
      this.processorLoader = false;
    }
  }

  /**
   * To get the value of activateWemloProcessing from broker collection
   */
  public async getWemloProcessing() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getWemloProcessing",
        { userId: this.$userId });
      this.activateWemPro = response.data.activateWemloProcessing;
    } catch (error) {
      console.log(error, "getWemloProcessing");
    }
  }
  /**
   * to activate the wemlo processing via setting true in activateWemloProcessing
   */
  public async activateProcessing(val) {
    try {
      let sure = false;
      let msg = !val
        ? "Are you sure you want to Deactivate Wemlo Processing?"
        : "Are you sure you want to Activate Wemlo Processing?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.activateWemPro = !this.activateWemPro;
          this.$snotify.info("No changes");
        });
      if (sure) {
        this.activateWemPro = val;
        this.activateWemloProcessing({
          wemloProcessor: this.activateWemPro,
          userId: this.$userId
        });

        // let response = await Axios.post(
        //   BASE_API_URL + "broker/activateWemloProcessing",
        //   {
        //     wemloProcessor: this.activateWemPro,
        //     userId: this.$userId
        //   },
        //   {
        //
        //   }
        // );
        // this.$snotify.success("Updated");
      }
    } catch (error) {
      console.log(error, "activateProcessing");
    }
  }
  /**
   * Fetching Details of PROCESSOR addedBy Broker with all roles
   */
  public async getBrokerStaffWithRoles() {
    try {
      this.loader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/getRolesForBroker",
        { userId: this.$userId });
      this.brokerStaff = response.data;
      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.log(error, "getBrokerStaffWithRoles");
    }
  }
  /**
   * To get the names of Role for table header
   */
  public async getRolesName() {
    try {
      let response = await Axios.get(BASE_API_URL + "superAdmin/getRoles");
      console.log(response,"response");

      this.roles = response.data.roles.filter(e => e.id != 8 && e.id != 9);
      this.taskRole = response.data.roles.filter(
        e => e.id == 7 || e.id == 8 || e.id == 9
      );
    } catch (error) {
      console.log(error, "getRolesName");
    }
  }

  public editBrokerStaff(i) {
    try {
      // this.$modal.show("addBrokerStaff");
      this.$modal.show("editDetails");
      this.brokerStaffInfo = this.brokerStaff[i];
    } catch (error) {
      console.log(error);
    }
  }
  public async editBrokerProcessor() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      this.inProcess = true;

      let response = await Axios.post(
        BASE_API_URL + "broker/editBrokerProcessor",
        {
          brokerStaffInfo: this.brokerStaffInfo,
          userId: this.$userId
        });
      this.inProcess = false;
      this.$snotify.success("Staff added successfully");
      this.cancel();
      this.getBrokerStaffWithRoles();
    } catch (error) {
      this.inProcess = false;

      console.log(error);
    }
  }

  public editEmail(modalName, body) {
    let data = {
      userType: "Wemlo Processor",
      modalName: modalName,
      firstName: body.firstName,
      lastName: body.lastName,
      email: body.email,
      userId: body.userId
    };

    this.personalInfo = [{ ...body, ...data }];
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }

  public async updateBorrowerInfo(updatedData) {
    await this.getBrokerStaffWithRoles();
  }

  async mounted() {
    await this.getWemloProcessing();
    await this.getBrokerStaffWithRoles();
    this.getRolesName();
  }
}
