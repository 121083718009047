

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import { Multiselect } from "vue-multiselect";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import StarRating from "vue-star-rating";

@Component({ components: { Multiselect, StarRating } })
export default class ManageProcessor extends mixins(CommonMixin) {
  public activateWemBal = false;
  // public confirmActivateWemloBalencing = false;
  public brokerProcessorList = [];
  public wemloProcessorList = [];
  public roles = [];
  public selectedRole = [];
  public brokerSelectedProcessor: any = [];
  public wemloSelectedProcessor: any = [];
  public userRatings = [];
  public validation = [];
  public message = "";
  public activateWemPro = true;
  public hasLoanSubmitted = false;
  public hasLoan = false;
  public allProcessors = [];
  public loader = true;
  public width = 0.8;
  public nameOfCurrentProcessor = null;
  public profilepicture = null;
  // public s: any = {};

  /**
   * To get the list of all processor which is added by wemlo team
   */
  public async getWemloProcessorList() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getProcessorList",
        { userId: this.$brokerId, type: "wemloProcessor" });
      this.wemloProcessorList = response.data;
    } catch (error) {
      console.log(error, "getWemloProcessorList");
    }
  }
  /**
   * To get the list of all processor which is added by broker
   */
  public async getBrokerProcessorList() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/getProcessorList",
        { userId: this.$brokerId, type: "brokerProcessor" });

      this.brokerProcessorList = response.data;
    } catch (error) {
      console.log(error, "getBrokerProcessorList");
    }
  }
  /**
   * filtering the roleId from  this.brokerSelectedProcessor and this.processorlist if this roleid is exit
   */
  public async checkIfRolesExist(selectedRole, processorId, type) {
    try {
      if (type == "Broker") {
        this.brokerSelectedProcessor = await this.brokerSelectedProcessor.filter(
          a => !(a.roleId == selectedRole.id)
        );
        await this.allProcessors.forEach(async e => {
          if (e.userId != processorId && !e.wemloMember)
            e.selectedRole = await e.selectedRole.filter(
              sr => sr.id != selectedRole.id
            );
        });
      } else {
        await this.allProcessors.forEach(async e => {
          if (e.userId != processorId && e.wemloMember)
            e.selectedRole = await e.selectedRole.filter(
              sr => sr.id != selectedRole.id
            );
        });
      }
    } catch (error) {
      console.log(error, "checkIfRolesExist");
    }
  }

  public async enableDisableCondition(type) {
    if (type == "broker") {
      this.wemloSelectedProcessor = [];
      if (this.brokerSelectedProcessor.length > 0)
        this.allProcessors = this.allProcessors.filter(e => !e.wemloMember);
      else {
        await this.toGetAllProcessors();
      }
    } else {
      this.brokerSelectedProcessor = [];
      if (this.wemloSelectedProcessor.length > 0) {
        this.allProcessors = this.allProcessors.filter(e => e.wemloMember);
        this.roles = this.roles.filter(e => e.id == 1);
      } else {
        await this.toGetAllProcessors();
        await this.getRoles();
      }
    }
  }

  // public async enableDisableCondition(type) {
  //   if (type == "broker") {
  //     this.wemloSelectedProcessor = [];
  //     if (this.brokerSelectedProcessor.length > 0)
  //       this.allProcessors = await this.allProcessors.filter(
  //         e => !e.wemloMember
  //       );
  //     else {
  //       await this.toGetAllProcessors();
  //     }
  //   } else {
  //     this.brokerSelectedProcessor = [];
  //     if (this.wemloSelectedProcessor.length > 0) {
  //       this.allProcessors = await this.allProcessors.filter(
  //         e => e.wemloMember
  //       );
  //       this.roles = this.roles.filter(e => e.id == 1);
  //     } else {
  //       await this.toGetAllProcessors();
  //       await this.getRoles();
  //     }
  //   }
  // }

  public async changeValue(selectedProcessor, checked) {
    try {
      if (selectedProcessor.wemloMember)
        await this.changeValueForWemloProcessor(selectedProcessor, checked);
      else await this.changeValueForBrokerProcessor(selectedProcessor, checked);
    } catch (error) {
      console.log(error);
    }
  }

  public async changeValueForBrokerProcessor(selectedProcessor, checked) {
    try {
      this.wemloSelectedProcessor = [];
      await this.allProcessors.forEach(async e1 => {
        if (e1.userId == selectedProcessor.userId) {
          if (checked) {
            await this.toStoreData(e1, "Broker");
            await this.enableDisableCondition("broker");
          } else {
            await this.toFilterRoles(selectedProcessor);
            this.brokerSelectedProcessor = await this.brokerSelectedProcessor.filter(
              a => selectedProcessor.userId != a.processorId
            );
            await this.enableDisableCondition("broker");
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async changeValueForWemloProcessor(selectedProcessor, checked) {
    try {
      this.wemloSelectedProcessor = [];
      await this.allProcessors.forEach(async e1 => {
        if (e1.userId == selectedProcessor.userId) {
          if (checked) {
            await this.toStoreData(e1, "Wemlo");
            // await this.enableDisableCondition("wemlo");
          } else {
            await this.toGetAllProcessors();
            await this.toFilterRoles(selectedProcessor);
            this.wemloSelectedProcessor = await this.brokerSelectedProcessor.filter(
              a => selectedProcessor.userId != a.processorId
            );
            // await this.enableDisableCondition("wemlo");
          }
        }
      });
    } catch (error) {
      console.log(error, "changeValueForWemloProcessor");
    }
  }

  public async toFilterRoles(selectedProcessor) {
    try {
      let roles = await this.roles.map(e => {
        if (
          e &&
          e.processorData &&
          e.processorData.processorId &&
          e.processorData.processorId == selectedProcessor.userId &&
          e.checked
        ) {
          e.processorData = {};
          e.checked = null;
          e.id = null;
          e.name = null;
        }
        return e;
      });
      // this.selectedRole = await Promise.all(roles);
      // this.selectedRole = await this.selectedRole.filter(
      //   e =>
      //     !(
      //       e.processorData.processorId.toString() ===
      //       selectedProcessor.userId.toString()
      //     )
      // );
    } catch (error) {
      console.log(error);
    }
  }

  public async toStoreData(e1, type) {
    try {
      await e1.selectedRole.forEach(async element => {
        await this.checkIfRolesExist(element, e1.userId, type);
        if (type == "Wemlo")
          await this.wemloSelectedProcessor.push({
            brokerId: this.$userId,
            roleId: element.id,
            processorId: e1.userId
          });
        else
          await this.brokerSelectedProcessor.push({
            brokerId: this.$userId,
            roleId: element.id,
            processorId: e1.userId
          });
        /************************************************************************
         *                      DO NOT REMOVE THE CODE                          *
         *                                                                      *
         ************************************************************************/
        await this.getRoles();
        let rolesData = await this.roles.map(async e => {
          if (e.id == element.id) {
            e["processorData"] = {
              processorId: e1.userId,
              profilePicture: e1.profilePicture,
              firstLetterOfname: e1.firstLetterOfName,
              checked: true,
              selectedProcessorName:
                e1.firstName + " " + (e1.lastName ? e1.lastName : " "),
              wemloMember: e1.wemloMember,
              processorRelationship: e1.wemloMember
                ? "Wemlo"
                : e1.processorRelationship
            };
            e["checked"] = true;
          }
          return e;
        });
        this.roles = await Promise.all(rolesData);
        // this.selectedRole;
        // let x: any = {};
        // await this.roles.forEach(async e => {
        //   if (e.id == element.id) {
        //     x.processorData = {
        //       processorId: e1.userId,
        //       profilePicture: e1.profilePicture,
        //       firstLetterOfname: e1.firstLetterOfName,
        //       checked: true,
        //       selectedProcessorName:
        //         e1.firstName + " " + (e1.lastName ? e1.lastName : " "),
        //       wemloMember: e1.wemloMember,
        //       processorRelationship: e1.wemloMember
        //         ? "Wemlo"
        //         : e1.processorRelationship
        //     };
        //     x.checked = true;
        //     x.id = e.id;
        //     x.name = e.name;
        //     await this.selectedRole.push(x);
        //   }
        // });
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * To get roles to display
   */
  public async getRoles() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "broker/getRolesForProcessorList");
      this.roles = response.data.roles;
      // this.roles = await this.roles.filter(e => e.id != 8 && e.id != 9);
      this.roles = await this.roles.filter(e => e.id == 1);
    } catch (error) {
      console.log(error, "getRolesName");
    }
  }

  /**
   * To check if any processor  doesnot have any role
   */
  public async validate() {
    try {
      this.validation = this.roles.filter(
        e =>
          (e.hasOwnProperty("checked") && !e.checked) ||
          !e.hasOwnProperty("checked")
      );
      if (this.validation.length > 0) return false;
      else return true;
    } catch (error) {
      console.log(error, "validate");
    }
  }
  /**
   * Saving processorId against role
   */
  public async saveProcessorOrSubmitToWemlo() {
    try {
      if (
        !this.activateWemBal &&
        this.brokerSelectedProcessor.length == 0 &&
        this.wemloSelectedProcessor.length == 0
      ) {
        this.$snotify.error("Please select Processor");
        return;
      }
      if (this.brokerSelectedProcessor.length > 0) {
        let validate = await this.validate();
        if (!validate) {
          this.$snotify.error("Please assign Processor to all task");
          return;
        }
      }
      let isWemloRoleAllocation;
      let selectedProcessor: any = [];
      if (this.brokerSelectedProcessor.length > 0) {
        selectedProcessor = this.brokerSelectedProcessor;
        isWemloRoleAllocation = false;
      } else if (this.wemloSelectedProcessor.length > 0) {
        selectedProcessor = this.wemloSelectedProcessor;
        isWemloRoleAllocation = true;
      } else isWemloRoleAllocation = true;
      this.hasLoanSubmitted = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/saveProcessorOrSubmitToWemlo",
        {
          brokerStaff: selectedProcessor,
          userId: this.$userId,
          loanTxnId: this.$route.query.loanTxnId,
          userType: this.$userType,
          isWemloRoleAllocation: isWemloRoleAllocation
        });
      if (response.data.message) {
        this.hasLoan = true;
        this.$snotify.error(response.data.message);
        this.hasLoanSubmitted = false;
      } else {
        this.$snotify.success("Loan submitted successfully");
        this.hasLoanSubmitted = false;
        this.$router.push("/dashBoard");
      }
    } catch (error) {
      this.hasLoanSubmitted = false;
      console.log(error, "saveProcessorOrSubmitToWemlo");
    }
  }
  /**
   * Average ratings and reviews of processor respective to broker and mlo
   */
  public async ratingsAndReviews(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/ratingsAndReviews",
        { id: id });
      this.userRatings = response.data;
    } catch (error) {
      console.log(error, "ratingsAndReviews");
    }
  }
  /**
   * Making  Processor Favorite
   */
  public async makingProcessorFavorite(val, i, pId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/makingProcessorFavorite",
        {
          userId: this.$userId,
          userType: this.$userType,
          processorId: pId,
          favorite: val
        });
    } catch (error) {
      console.log(error, "makingProcessorFavorite");
    }
  }

  /************************************************************************
   *                      DO NOT REMOVE THE CODE                          *
   *                                                                      *
   ************************************************************************/
  // public async removeUnselectProcessor(selectedProcessor, x) {
  //   try {
  //     this.allProcessors[x].checked = false;
  //     let roles = this.roles.map(e => {
  //       if (
  //         e &&
  //         e.processorData &&
  //         e.processorData.processorId &&
  //         e.processorData.processorId == selectedProcessor.userId &&
  //         e.checked
  //       ) {
  //         e.processorData = {};
  //         e.checked = false;
  //       }
  //       return e;
  //     });
  //     this.roles = await Promise.all(roles);

  //     this.brokerSelectedProcessor = this.brokerSelectedProcessor.filter(
  //       a => selectedProcessor.userId != a.processorId
  //     );
  //     // this.allProcessors[x].selectedRole.forEach(async element => {
  //     //   this.roles.map(e => {
  //     //     if (e.id == element.id && e.checked) {
  //     //       e.processorData = {};
  //     //       e.checked = false;
  //     //     }
  //     //   });
  //     // });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  /**
   * To activate the the WEMLO LOAD Balancing
   * if we switch on the wemlo processing then wemlo team will the handle the loan
   * else broker's selcted processor will handle the loan
   */
  public async activateWemloBalancing(val) {
    try {
      this.activateWemBal = val;
      if (val) {
        // this.selectedRole = [];
        this.wemloSelectedProcessor = [];
        this.brokerSelectedProcessor = [];
      }
      if (this.activateWemBal) {
        this.allProcessors = [];
      } else {
        this.wemloSelectedProcessor = [];
        this.brokerSelectedProcessor = [];
        await this.toGetAllProcessors();
        await this.getRoles();
      }
    } catch (error) {
      console.log(error, "activateWemloBalancing");
    }
  }
  /**
   * To keep both user's processor in one variable
   */
  public async toGetAllProcessors() {
    try {
      await this.getBrokerProcessorList();
      await this.getWemloProcessorList();
      this.loader = true;
      this.allProcessors = [];
      if (this.brokerProcessorList.length > 0) {
        await this.brokerProcessorList.forEach(async e => {
          await this.allProcessors.push(e);
        });
      }
      if (this.wemloProcessorList.length > 0) {
        await this.wemloProcessorList.forEach(async e => {
          await this.allProcessors.push(e);
        });
      }
      this.loader = false;
    } catch (error) {
      this.loader = false;

      console.log(error);
    }
  }
  // public async ShowProfilePicture(bList) {
  //   try {
  //     this.nameOfCurrentProcessor = bList.firstName + " " + bList.lastName;
  //     if (bList.profilePicture) {
  //       this.profilepicture = bList.profilePicture;
  //       let arr = bList.profilePicture.split(".");
  //       let ext = arr.pop();
  //       let src = arr[0].substring(0, 58);
  //       this.profilepicture = src + "small." + ext;
  //     } else {
  //       this.profilepicture = null;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // **********************Don't Remove bellow code******************************************

  // public async ShowProfilePicture(bList) {
  //   try {
  //     this.nameOfCurrentProcessor = bList.firstName + " " + bList.lastName;
  //     if (bList.profilePicture) {
  //       this.profilepicture = bList.profilePicture;
  //       let arr = bList.profilePicture.split(".");
  //       let ext = arr.pop();
  //       let src = arr[0].substring(0, 58);
  //       this.profilepicture = src + "thumb." + ext;
  //       let url = src + "thumb." + ext;
  //       var image = new Image();
  //       image.src = url;
  //       image.onload = () => {
  //         this.s.width = image.width * 2;
  //         this.s.height = image.height * 2;
  //       };
  //     } else {
  //       this.profilepicture = null;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  private async loadImage(path, el) {
    try {
      let ext = path.split('.').pop();
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path });
      if(ext != 'pdf') response.data = 'data:image/'+ext+';base64,'+response.data;
      let dom = document.getElementById(el);
      if(!dom) return;
      dom['src'] = response.data;
      dom.append();
    } catch (error) {
      console.log(error);
    }
  }

  private popUpImage(i) {
    try {
      let img = document.getElementById('listImages'+i);
      let popUpImage = document.getElementById('popUpImage'+i);
      if( !popUpImage || !img ) return;
      popUpImage['src']  = img['src'];
      popUpImage.append();
    } catch (error) {
      console.log(error);
    }
  }
  async mounted() {
    await this.getRoles();
    await this.toGetAllProcessors();
  }
}
